import './Toast.css'

import gsap from 'gsap'
import { useRef, useEffect } from 'react'

const Toast = ({infoType, info, setInfoType}) => {
    const bar = useRef()
    const infoComp = useRef()

    useEffect(() => {
        gsap.set(infoComp.current,{opacity:0,y:-30})

        gsap.to(infoComp.current,{opacity:1,y:0,ease:"none",duration:.5,onComplete:() => {
            gsap.to(bar.current, {x:'-100%',duration:2,ease:"none",onComplete: () => {
                gsap.to(infoComp.current,{opacity:0,duration:.5,onComplete:() => {
                    setInfoType("")
                }})
            }})
        }})

    },[info])

    const chooseClass = () => {
        switch(infoType){
            case "Succes":
                return "Toast Toast__success"
            break;

            case "Fail":
                return "Toast Toast__fail"
            break;               
        }
    }
    const chooseImg = () => {
        switch(infoType){
            case "Succes":
                return <img src="https://doubleespresso.pl/images/preview/check-mark-icon.svg" className='Toast__img'/>
            break;

            case "Fail":
                return <img src="https://doubleespresso.pl/images/preview/close-icon.svg" className='Toast__img'/>
            break;               
        }
    }

    const handleClick = () => {
                gsap.to(infoComp.current,{opacity:0,duration:.5,onComplete:() => {
                    setInfoType("")
                }})

    }

    return(
        <div className={chooseClass()} ref={infoComp}>
            {chooseImg()}
            <p draggable="false" className='Toast__txt'>{info}</p>
            <div draggable="false" className='Toast__progress' ref={bar}></div>
            <div draggable="false" className='Toast__close-notification' onClick={handleClick}>X</div>
        </div>
    )
}

export default Toast